import React from "react"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import TwitterIcon from "../images/social-media/twitter.svg"
import GitHubIcon from "../images/social-media/github.svg"
import LinkedInIcon from "../images/social-media/linkedin.svg"
import AngelListIcon from "../images/social-media/angellist.svg"
import ColorPalette from "../utils/colorPalette"

const SocialMediaLink = styled(OutboundLink)`
  img {
    margin: 0;
    display: block;
    height: 1.6em;
    width: 1.6em;

    ${ColorPalette.filter.interactiveYellow};
  }

  transition: all 0.2s ease-out;
  &:hover { filter: brightness(110%); }
  &:active { filter: brightness(90%); }
`

export default () => {
    return (
        <>
            <SocialMediaLink href="https://twitter.com/przemekblasiak"><img src={TwitterIcon} alt="Link to Twitter"/></SocialMediaLink>
            <SocialMediaLink href="https://www.linkedin.com/in/przemekblasiak"><img src={LinkedInIcon} alt="Link to LinkedIn"/></SocialMediaLink>
            <SocialMediaLink href="http://github.com/przemekblasiak"><img src={GitHubIcon} alt="Link to GitHub"/></SocialMediaLink>
            <SocialMediaLink href="https://angel.co/u/przemekblasiak"><img src={AngelListIcon} alt="Link to AngelList"/></SocialMediaLink>
        </>
    )
}