const ColorPalette = {
    backgroundGray: "#EBE9E8",
    backgroundBurgundy: "#38040E",
    textBlack: "black",
    interactiveYellow: "#FCBB35",

    /* Changing color of the SVG using filters, calculate here: https://codepen.io/sosuke/pen/Pjoqqp. */
    filter: { 
        interactiveYellow: "filter: invert(78%) sepia(44%) saturate(843%) hue-rotate(335deg) brightness(103%) contrast(98%)",
    }
}

export default ColorPalette;