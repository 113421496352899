import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import PrzemekSignature from "../images/przemek-signature.png"
import Media from "../utils/media.js"

const Letter = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr fit-content;
  grid-template-areas:
    "CN"
    "SG";
`

const Content = styled.div`
  grid-area: CN;
  text-align: justify;
`

const Signature = styled.div`
  grid-area: SG;
  justify-self: end;
  img {
    height: 1em;
    margin: 0;
    display: block;
  }

  ${Media.queryFor(Media.mobile)} {
    display: none;
  }
`

export default ({ children }) => {
    const data = useStaticQuery(graphql`{
        allMarkdownRemark(filter: {frontmatter: {title: {eq: "Letter"}}}) {
            nodes {
                html
            }
        }
    }`)

  return (
    <Letter>
      <Content><div dangerouslySetInnerHTML={{ __html: data.allMarkdownRemark.nodes[0].html }} /></Content>
      <Signature><img src={PrzemekSignature} alt="Przemek's signature" /></Signature>
    </Letter>
  )
}