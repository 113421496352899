import React from "react"
import styled from 'styled-components'

import ContactButton from "./contactButton.js"
import ColorPalette from "../utils/colorPalette.js"

const Container = styled.div`
  background-color: ${ColorPalette.backgroundBurgundy};
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "CC";
  align-items: center;
  justify-items: center;
`

const ContactContainer = styled.div`
  grid-area: CC;
`

export default ({ children }) => (
  <Container>
    <ContactContainer><ContactButton /></ContactContainer>
  </Container>
)