import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      imageFile: file(relativePath: { eq: "przemek.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img 
      fluid={data.imageFile.childImageSharp.fluid} alt="Smiling Przemek" 
      style={{ maxHeight: '80vh' }}
      imgStyle={{ objectFit: 'contain' }}
    />
  )
}


// imgStyle={{ objectFit: 'contain' }