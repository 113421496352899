import React from "react"
import styled from "styled-components"
import { createGlobalStyle } from "styled-components"

import Image from "./image"
import Header from "./header"
import Footer from "./footer"
import Letter from "./letter"
import Portfolio from "./portfolio"
import Media from "../utils/media.js"
import ColorPalette from "../utils/colorPalette"

const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${ColorPalette.backgroundGray};

    ${Media.queryFor(Media.mobile)} {
      font-size: calc(6px + 0.8vw + 0.8vh);
    }
  
    ${Media.queryFor(Media.tablet)} {
      font-size: calc(14px);
    }

    ${Media.queryFor(Media.laptop)} {
      font-size: calc(18px);
    }

    ${Media.queryFor(Media.desktop)} {
      font-size: calc(22px);
    }
  }

  body {
    margin: 0;
    padding: 0;
    height: calc(var(--vh, 1vh) * 100); 
    width: 100vw;
  }  
`

const MainContainer = styled.main`
  height: calc(var(--vh, 1vh) * 100); 
  width: 100%;

  ${Media.queryFor(Media.mobile)} {
    display: grid;
    grid-template-columns: 6vw 1fr 6vw;
    grid-template-rows: 4em minmax(4em, 1fr) min-content 1fr min-content 1em 5em;
    grid-template-areas:
      "HC HC HC"
      ".  .  . "
      ".  LC . "
      ".  .  . "
      ".  PC . "
      ".  .  . "
      "FC FC FC";
  }

  ${Media.queryFor(Media.tablet, Media.laptop, Media.desktop)} {
    display: grid;
    grid-template-columns: 1fr 1fr 10vw;
    grid-template-rows: 4em 1fr max-content 1fr 1fr 4em;
    grid-template-areas:
      "HC HC HC"
      ".  .  . "
      "IC LC . "
      "IC .  . "
      "IC PC . "
      "IC .  . ";
  }
`

const HeaderContainer = styled.div`
  grid-area: HC;
  align-self: stretch;
`

const FooterContainer = styled.div`
  grid-area: FC;
  align-self: stretch;

  ${Media.queryFor(Media.tablet, Media.laptop, Media.desktop)} { display: none; }
`

const ImageContainer = styled.div`
  grid-area: IC;
  align-self: end;
`

const LetterContainer = styled.div`
  grid-area: LC;
  align-self: center;
`

const PortfolioContainer = styled.div`
  grid-area: PC;
  align-self: end;
`

export default ({ children }) => (
  <>
    <GlobalStyle />
    <MainContainer>
      <HeaderContainer><Header/></HeaderContainer>
      <ImageContainer><Image /></ImageContainer>
      <LetterContainer><Letter /></LetterContainer>
      <PortfolioContainer><Portfolio /></PortfolioContainer>
      <FooterContainer><Footer /></FooterContainer>
    </MainContainer>
  </>
)