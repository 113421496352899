import React from "react"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import estimoteIcon from "../images/portfolio/estimote.png"
import tukutukuIcon from "../images/portfolio/tukutuku.png"
import tabutabuIcon from "../images/portfolio/tabutabu.png"
import lendbookIcon from "../images/portfolio/lendbook.png"
import aghacksIcon from "../images/portfolio/aghacks.png"
import ezenseIcon from "../images/portfolio/ezense.png"
import ColorPalette from "../utils/colorPalette"

const Portfolio = styled.div`
    display: grid;
    grid-template-columns: fit-content 1fr;
    grid-template-rows: 1em 1em 1fr;
    grid-template-areas:
        "TT . "
        ".  . "
        "AS . ";
`

const Title = styled.h3`
    text-align: center;
    font-weight: normal;
    color: ${ColorPalette.textBlack};
    opacity: 0.2;
    margin: 0;
    margin-bottom: 0.5em;

    grid-area: TT;
`

const AppsSection = styled.div`
    background-color: rgb(255,255,255,0.5);

    padding: calc(0.15em + 0.5vw + 0.5vh);
    border-radius: calc(0.15em + 0.5vw + 0.5vh);;

    grid-area: AS;
    display: grid;
    grid-auto-flow: column;
    grid-gap: calc(0.15em + 0.5vw + 0.5vh);;
`

const AppTile = styled(OutboundLink)`
    img {
        border-radius: calc(0.8 * (0.15em + 0.5vw + 0.5vh));;
        display: block;
        margin: 0;
    }

    transition: all 0.2s ease-out;
    &:hover { filter: brightness(110%); }
    &:active { filter: brightness(90%); }
`

export default () => {
    return (
    <Portfolio>
        <Title>My portfolio</Title>
        <AppsSection>
            <AppTile href="https://estimote.com"><img src={estimoteIcon} alt="Estimote icon"/></AppTile>
            <AppTile href="https://apps.apple.com/app/id1111506368"><img src={tukutukuIcon} alt="Tuku Tuku icon"/></AppTile>
            <AppTile href="https://apps.apple.com/app/id1453412745"><img src={tabutabuIcon} alt="Tabu Tabu icon"/></AppTile>
            <AppTile href="https://appadvice.com/app/lendbook-lend-borrow-money-and-items/1077002863"><img src={lendbookIcon} alt="Lendbook icon"/></AppTile>
            <AppTile href="https://apps.apple.com/us/app/aghacks/id1050461825"><img src={aghacksIcon} alt="AGHacks icon"/></AppTile>
            <AppTile href="https://www.facebook.com/company.ezen.se"><img src={ezenseIcon} alt="Ezen.se icon"/></AppTile>
        </AppsSection>
    </Portfolio>
    )
}