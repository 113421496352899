import React from "react"
import styled from "styled-components"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Media from "../utils/media.js"
import ColorPalette from "../utils/colorPalette.js"

const ContactButton = styled.div`
    cursor: pointer;
    transition: all 0.2s ease-out;
    &:hover { filter: brightness(110%);}
    &:active { filter: brightness(90%); }
`

const Text = styled.h3`
    margin: auto;
    color: ${ColorPalette.interactiveYellow};
    border: 2px solid ${ColorPalette.interactiveYellow};
    border-radius: 0.5em;
    padding-top: 0.6em;
    padding-bottom: 0.5em;

    ${Media.queryFor(Media.mobile)} {
        padding-left: 2.5em;
        padding-right: 2.5em;
    }

    ${Media.queryFor(Media.tablet, Media.laptop, Media.desktop)} {
        padding-left: 1.2em;
        padding-right: 1.2em;
    }
`

function onClick() {
    trackCustomEvent({ category: "Contact Button", action: "Click" });
    var encodedURL = "bWFpbHRvOmJsYXNpYWsucHJ6ZW15c2xhdyt3ZWJzaXRlQGdtYWlsLmNvbQ==";
    window.location.href = atob(encodedURL);
}

export default () => { 
    return (
        <ContactButton onClick={onClick}>
            <Text>Get in touch</Text>
        </ContactButton>
    )
}