import React from "react"
import styled from 'styled-components'
import { OutboundLink } from "gatsby-plugin-google-analytics"

import ContactButton from "./contactButton.js"
import SocialMedia from "./socialMedia.js"
import Media from "../utils/media.js"
import PrzemekFace from "../images/przemek-face.png"
import ColorPalette from "../utils/colorPalette.js"

const Container = styled.div`
  background-color: ${ColorPalette.backgroundBurgundy};
  height: 100%;

  ${Media.queryFor(Media.mobile)} {
    display: grid; 
    grid-template-columns: 8vw max-content 1fr max-content 8vw;
    grid-template-rows: 1.2em 1fr 1em;
    grid-template-areas:
      ".  .  .  .  . "
      ".  .  .  SC . "
      ".  FL .  .  . ";
    align-items: center;
  } 

  ${Media.queryFor(Media.tablet, Media.laptop, Media.desktop)} {
    display: grid;
    grid-template-columns: 5vw max-content 1fr max-content 2em max-content 4vw;
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
      ".  .  .  .  .  .  . "
      ".  HL .  SC .  IC . ";
      ".  .  .  .  .  .  . ";
    align-items: center;
  }
`

const HomepageLink = styled(OutboundLink)`
  color: white;
  text-decoration: none;
  h1 {
    margin: 0;
    margin-top: 0.25em;
  }

  grid-area: HL;
  justify-self: start;
  align-self: center;

  ${Media.queryFor(Media.mobile)} { display: none; }
`

const SocialContainer = styled.div`
  display: grid;
  grid-area: SC;
  grid-gap: 1em;
  grid-auto-flow: column;
`

const InteractiveContainer = styled.div`
  margin: 0;
  grid-area: IC;

  ${Media.queryFor(Media.mobile)} { display: none; }
`

const FaceLink = styled(OutboundLink)`
  img {
    height: 5.3em;
    background-color: ${ColorPalette.backgroundGray};
    border-radius: 100%;
    margin-bottom: 0;
    margin-top: 1em;
    display: block;
  }
  
  grid-area: FL;

  ${Media.queryFor(Media.tablet, Media.laptop, Media.desktop)} { display: none; }
`

export default ({ children }) => (
  <Container>
    <FaceLink href="/"><img src={PrzemekFace} alt="Przemek's face" /></FaceLink>
    <HomepageLink href="/"><h1>przemek blasiak</h1></HomepageLink>
    <SocialContainer><SocialMedia /></SocialContainer>
    <InteractiveContainer><ContactButton /></InteractiveContainer>
  </Container>
)