function queryForWidthRange(minWidth, maxWidth) {
    return `only screen and (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
}

export default {
    queryFor: (...deviceList) => {
        var cssRule = `@media ${deviceList.pop()}`;
        deviceList.forEach(device => {
            cssRule = `${cssRule}, ${device}`;
        });
        return cssRule;
    },
    mobile: queryForWidthRange(0, 1023),
    tablet: queryForWidthRange(1024, 1599),
    laptop: queryForWidthRange(1600, 2599),
    desktop: queryForWidthRange(2560, 99999),
}